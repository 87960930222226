.checkout-item-container {
    width: 100%;
    display: flex;
    min-height: 100px;
    border-bottom: 1px solid darkgrey;
    padding: 15px 0;
    font-size: 20px;
    // justify-content:space-between;
    align-items: center;
  
    .image-container {
      min-width: 23%;
      max-width: 23%;
      padding-right: 15px;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name,
    .quantity,
    .price {
      min-width: 25%;
      // margin-left: 3vw;

    }
  
    .quantity {
      display: flex;
  
      .arrow {
        cursor: pointer;
        padding: 0 10px;
      }
  
      .value {
        margin: 0 10px;
      }
    }
  
    .remove-button {
      margin-left: auto;
      cursor: pointer;
    }
  }
  