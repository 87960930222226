.navigation {
    position:fixed;
    top:0; 
    left:0;
    background-color: white;
    z-index: 2;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  
    .logo-container {
      height: 100%;
      width: 70px;
      padding: 15px;
    }
  
    .nav-links-container {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        .nav-link {
        padding: 20px 20px 5px 20px;
        cursor: pointer;
      }
    }
    .overflow-container {
      overflow:visible;
    }
  }

  .heigth-balancer{
    position: static;
    height: 80px;
  }
  
  @media only screen and (max-width: 480px){
    .navigation {
      .nav-links-container{
        .nav-link {
          padding: 20px 5px;
          margin-top: 15px;
        }
      }
    }
  }