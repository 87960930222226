.sign-in-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 40px;

  h2{
    margin: 10px 0px;
  }
}

.buttons-container {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (min-width: 480px) {

  .buttons-container {
    flex-direction: row;
    justify-content: space-between;

  }
}

@media only screen and (min-width: 781px) {
  .sign-in-container {
    width: 380px;
  }
}
