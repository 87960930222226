.payment-form-wrapper {
    margin:20px;
    border: 1px solid #cfcfcf;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    width: 340px;
    padding: 10px 30px;
}

.stripe-element-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fullsize-button {
width: 300px;
}

.payment-form-wrapper button {
    margin:10px 0;
}