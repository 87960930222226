.categories-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media only screen and (min-width: 600px) {
    .categories-container {
      flex-direction: row;
    }
  }
    
  
  