.category-preview-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

  h2 {
    text-align: center;
  }

    .title {
      font-size: 28px;
      margin-bottom: 25px;
      cursor: pointer;
    }
    .category-preview-subtitle {
      padding: 0;
      margin:-20px 0 20px 0;
      text-align: center;
      width: 100%;
      font-size: 16px;
    }
    .preview {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: 20px;
    }
  }
  
  @media screen and (min-width: 481px) {
    .category-preview-container {
    .preview {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
    }
  }
  }

  @media screen and (min-width: 781px) {
    .category-preview-container {
    .preview {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 20px;
    }
  }
  }