.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 90%;

  h2{
    margin: 10px 0px;
  }
}

@media only screen and (min-width: 781px) {
  .sign-up-container {
    width: 40%;
  }
}