.cart-icon-container {
    margin-top: 7px;
    width: 45px;
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    .shopping-icon {
      width: 24px;
      height: 24px;
    }
  
    .item-counter {
      color: #cf6d6d;
      ;
      position: absolute;
      font-size: 12px;
      font-weight: bold;
      bottom: 12px;
    }
  }
  