.authentication-container { 
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    max-width:96vw;
    height: auto;
    margin: 30px 0px;
    padding: 20px 0;
    justify-content: space-around;
    align-items: center;
   

}

@media only screen and (min-width: 781px) {
    .authentication-container { 
      flex-direction: row;
      justify-content: space-between;
      align-items:flex-start;
      margin: 30px;
      padding: 20px;
    }
  }