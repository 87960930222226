.single-category-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 10px;
    row-gap: 50px;

}

.category-title {
    text-align: center;
    font-size: 38px;
    margin-bottom: 25px;
    cursor: pointer;
}

@media screen and (min-width: 481px) {
    .single-category-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
  }
  }

  @media screen and (min-width: 781px) {
    .single-category-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 20px;  
  }
  }